import { useState, useReducer, useEffect, useCallback } from 'react'
import axios from 'axios'

const API_URL = process.env.GATSBY_API_URL
const _dataMethods = ['delete', 'patch', 'post', 'put']

const initialState = {
  isLoading: false,
  success: null,
  error: null,
  res: { data: {} },
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'request_init':
      return {
        ...initialState,
        isLoading: true,
      }

    case 'request_success':
      return {
        ...state,
        isLoading: false,
        success: true,
        res: action.payload,
      }

    case 'request_error':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      }

    case 'reset_success':
      return {
        ...state,
        success: initialState.success,
      }

    case 'reset_error':
      return {
        ...state,
        error: initialState.error,
      }

    default:
      throw new Error(`Invalid action type: ${action.type}`)
  }
}

const usePublicApi = initialConfig => {
  const [_config, setConfig] = useState(initialConfig)
  const [state, dispatch] = useReducer(reducer, initialState)

  const resetError = useCallback(() => dispatch({ type: 'reset_error' }), [])
  const resetSuccess = useCallback(
    () => dispatch({ type: 'reset_success' }),
    []
  )
  const sendRequest = useCallback(newConfig => setConfig(newConfig), [])

  useEffect(() => {
    let _isMounted = true

    // If we don't have an initialConfig then we don't send the request
    // until a config is set.
    if (!_config) {
      return
    }

    if (!_config.url) {
      throw new Error('Public api url is required')
    }

    if (_config.data && !_dataMethods.includes(_config.method)) {
      throw new Error('Data can only be sent with DELETE, PATCH, POST or PUT')
    }

    const axiosConfig = { baseURL: API_URL, ..._config }

    const send = async () => {
      if (_isMounted) {
        dispatch({ type: 'request_init' })
      }

      try {
        const _res = await axios(axiosConfig)

        if (_isMounted) {
          dispatch({ type: 'request_success', payload: _res })
        }
      } catch (_err) {
        if (_isMounted) {
          dispatch({ type: 'request_error', payload: _err })
        }
      }
    }

    send()

    // Prevents state transitions once the component is unmounted
    return () => {
      _isMounted = false
    }
  }, [_config])

  return [state, { sendRequest, resetError, resetSuccess }]
}

export default usePublicApi
